import React, { useState, useCallback, useEffect } from 'react'
import { database } from '../../firebase'
import { ListGroup, Spinner, Image } from 'react-bootstrap'
import DashboardListItem from './DashboardListItem'

export default function DashboardList({
  dashboardType,
  listState,
  setListState
}) {
  // const customerRef = database.customers.doc(listItemData.idNumber)
  // const listItemRef = customerRef.collection(dashboardType).doc(listItem.id)
  // const [listState, setListState] = useState([])

  useEffect(() => {
    // console.log('*** dashboardType:', dashboardType)
    database[dashboardType].orderBy('createdAt', 'desc').get().then((querySnapshot) => {
      setListState(querySnapshot.docs)
    }).catch((error) => {
      console.log(`Error getting all ${dashboardType}:`, error)
    })
  }, [dashboardType])

  return (
    <>
      {/* <button onClick={handleTSV}>
        TSV
      </button> */}

      {listState.length > 0 ?
      <ListGroup variant="flush">
        {listState.map((listItem, idx) => (
          <DashboardListItem
            key={listItem.id}
            dashboardType={dashboardType}
            listItem={listItem}
            idx={idx}
            listState={listState}
            setListState={setListState}
          />
        ))}
      </ListGroup> :
      <div className="NgDashboard-loadingContainer">
        <Spinner className="NgDashboard-loadingSpinner" animation="border" />
        <Image className="NgDashboard-loading" src={`${process.env.PUBLIC_URL}/assets/lick.gif`} />
      </div>}
    </>
  )
}
