import React from 'react'
import {
  Form,
  Row,
  Col
} from 'react-bootstrap'
import InputField from '../fields/InputField'

function AddressFormGroup({ section, formik, handleBlur }) {
  return (
    <Form.Group className="mb-0">
      <Row>
        <Col className="mb-3 mb-md-0" md>
          <Form.Label>Dirección de {section === 'billing' ? 'Facturación' : 'Envío'}</Form.Label>
          <InputField
            name={section + 'Address'}
            label="ej. CALLE 80C SUR # 23 - 12"
            formik={formik}
            handleBlur={handleBlur}
            maxLength={42}
          />
        </Col>
        <Col md>
          <Form.Label>Información Adicional <i>(opcional)</i></Form.Label>
          <InputField
            name={section + 'AdditionalInfo'}
            label="ej. BARRIO, CONJUNTO, # DE CASA, APTO"
            formik={formik}
            handleBlur={handleBlur}
            maxLength={86}
          />
        </Col>
      </Row>
    </Form.Group>
  )
}

export default AddressFormGroup
