import React, { useState, useMemo } from 'react'
import copy from "../constants/copy"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import join from 'lodash/join'
import {
  Card,
  Button
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faCheck,
  faPrint
} from '@fortawesome/free-solid-svg-icons'
import InfoCardItem from './InfoCardItem'
import PrintModal from "../modals/PrintModal"

function InfoCard({
  type,
  section,
  formState
}) {
  const isDashboard = type === 'purchases' || type === 'quotes' || type === 'exchanges' || type === 'warranties'
  const isPurchaseDash = type === 'purchases'
  const isPurchaseFormOrDash = type === 'purchase' || type === 'purchases'
  const isQuoteFormOrDash = type === 'quote' || type === 'quotes'
  const isExchangeFormOrDash = type === 'exchange' || type === 'exchanges'
  const isWarrantyFormOrDash = type === 'warranty' || type === 'warranties'
  const isReturnFormOrDash = type === 'return' || type === 'returns'
  const isBillingSection = section === 'billing'
  const isShippingSection = section === 'shipping'
  const [copied, setCopied] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)

  const fullName = useMemo(() => join([
    formState[section + 'FirstName'],
    ...(formState[section + 'MiddleName'] !== '' ? [formState[section + 'MiddleName']] : []),
    formState[section + 'LastName']
  ], ' '), [formState, section])

  const instagramUser = useMemo(() => (
    (!isShippingSection && formState.instagramUser && formState.instagramUser !== '') ? ` (${formState.instagramUser})` : ''
  ), [formState, isShippingSection])

  const address = useMemo(() => (
    // `${formState[section + 'Address']}\n${formState[section + 'AdditionalInfo'] !== '' ? (formState[section + 'AdditionalInfo'] + '\n') : ''}${formState[section + 'City']} - ${formState[section + 'Department'].department}`

    `${formState[section + 'Address']}\n` +
    `${formState[section + 'AdditionalInfo'] !== '' ? (formState[section + 'AdditionalInfo'] + '\n') : ''}` +
    `${formState[section + 'City']} - ` +
    `${formState[section + 'Department'] ? formState[section + 'Department'].department : ''}`
  ), [formState, section])

  // console.log('*** type:', type)
  // console.log('*** isReturnFormOrDash:', isReturnFormOrDash)
  // console.log('*** check:', !(isQuoteFormOrDash || isReturnFormOrDash))

  const items = useMemo(() => ({
    'Nombre': fullName + instagramUser,
    ...(!isShippingSection && { [formState.idType]: formState.idNumber }),
    'Celular': formState[section + 'Phone'],
    'Email': formState.email,
    ...((isPurchaseFormOrDash || isExchangeFormOrDash || isWarrantyFormOrDash) && {
      'Dirección': address
    }),
    ...(isReturnFormOrDash && {
      'Banco': formState.bank,
      'Cuenta': formState.accountNumber,
      'Tipo': formState.accountType
    })
    // 'Dirección': address
  }), [section, formState, isBillingSection, isShippingSection, fullName, instagramUser, address])

  const shipTo = useMemo(() => [
    fullName,
    formState[section + 'Phone'],
    address
  ], [fullName, formState, section, address])

  return (
    <Card bg="light" text="dark" className="h-100">
      <Card.Header as="h6" className="InfoCard-header">
        <span>{copy.InfoCard.header[section][+ (formState.diffShippingInfo || false)]}</span>
        {isDashboard && <div>
          <CopyToClipboard
            text={Object.values(items).join('\n')}
            onCopy={() => {
              setCopied(true)
              setTimeout(() => setCopied(false), 3000)
            }}>
            <Button size="sm" variant="secondary" className="InfoCard-headerBtn">
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            </Button>
          </CopyToClipboard>

          {(isPurchaseDash && !(formState.diffShippingInfo && isBillingSection)) && <>
            <Button size="sm" variant="secondary" className="InfoCard-headerBtn" onClick={() => {setShowPrintModal(true)}}>
              <FontAwesomeIcon icon={faPrint} />
            </Button>
            <PrintModal
              showPrintModal={showPrintModal}
              setShowPrintModal={setShowPrintModal}
              shipTo={shipTo}
            />
          </>}
        </div>}
      </Card.Header>

      <Card.Body className="d-flex flex-column font-size-09">
        {Object.keys(items).map((keyName, i) => (
          <InfoCardItem key={section + i} label={keyName} value={items[keyName]} />
        ))}
      </Card.Body>
    </Card>
  )
}

export default InfoCard
