import React, { useState } from 'react'
import copy from "../constants/copy"
import contactChannels from "../constants/contactChannels"
import {
  Card,
  Form,
  Row,
  Col,
  Image,
  Button
} from 'react-bootstrap'
import InputField from "../fields/InputField"
import PieceFormGroup from "../formGroups/PieceFormGroup"
import TransferFormGroup from "../formGroups/TransferFormGroup"
import RingSizeFormGroup from "../formGroups/RingSizeFormGroup"
import InputArea from '../fields/InputArea'
import CheckboxField from '../fields/CheckboxField'
import SelectField from "../fields/SelectField"
import MultipleFileFormGroup from '../formGroups/MultipleFileFormGroup'
import CopyField from '../fields/CopyField'
import QRModal from '../modals/QRModal'

export default function DetailsCard(props) {
  const isPurchase = props.formType === 'purchase'
  const isQuote = props.formType === 'quote'
  const isExchange = props.formType === 'exchange'
  const isWarranty = props.formType === 'warranty'
  const isReturn = props.formType === 'return'
  const isRingSize = props.formType === 'ringSize'
  const [showQRModal, setShowQRModal] = useState(false)

  return (
    <Card bg="light" text="dark" className="mt-3">
      <Card.Header as="h5">
        {copy.DetailsCard.header[props.formType]}
      </Card.Header>
      <Card.Body>
        {/* {isPurchase && <ProductFormGroup {...props} />} */}
        {/* {isQuote && <PieceFormGroup {...props} />} */}
        {isReturn && <TransferFormGroup {...props} />}
        {/* {isRingSize && <RingSizeFormGroup {...props} />} */}

        <Form.Group className="mb-0">
          <Form.Label>Detalles de tu {copy.action[props.formType]}</Form.Label>
          <InputArea name="details" placeholder="ej. Anillo de Gato, talla 5, color rosa..." {...props} />
        </Form.Group>

        {(isPurchase || isExchange || isWarranty) && <Form.Group className="mb-0">
          <Row>
            {isPurchase && <Col className="pt-4" md>
              <CheckboxField
                name="sendRingSizer"
                label={["Deseo que me envies un medidor de anillos ", <i key="i">(aplica solo para compras de anillos, no aplica para anillo Carbonífero o Keramos)</i>]}
                {...props}
              />
            </Col>}
            {(isExchange || isWarranty) && <Col className="pt-4">
              <Form.Label className="d-flex justify-content-between align-items-center">
                No. de pedido, factura ó nombre de quien te dió el regalo
              </Form.Label>
              <InputField name="receiptId" label="No. de pedido, factura ó nombre de quien te dió el regalo" {...props} />
            </Col>}
          </Row>
        </Form.Group>}

        {isPurchase && <Row>
          <Col className="pt-4" lg>
            <Card bg="light" text="dark">
              <Card.Header as="h6">Código QR para consignación</Card.Header>
              <Card.Body>
                <div className="QR">
                  <div className="QR-infoContainer">
                    Puedes hacer click en la imagen para agrandarla y tomar una captura de pantalla o descargar la imagen.
                    <Button variant="ng-primary" className="QR-button w-100" href={`${process.env.PUBLIC_URL}/assets/ngsas-qr.jpg`} download="NinaGatoQR">
                      Descargar
                    </Button>
                  </div>
                  <div className="QR-imageContainer">
                    <Image className="QR-image" src={`${process.env.PUBLIC_URL}/assets/ngsas-qr-thumbnail.jpg`} thumbnail onClick={() => {setShowQRModal(true)}} />
                    <QRModal showQRModal={showQRModal} setShowQRModal={setShowQRModal} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col className="pt-4" lg>
            <Card bg="light" text="dark" className="w-100 h-100">
              <Card.Header as="h6">Información para consignación</Card.Header>
              <Card.Body className="d-flex flex-column justify-content-between">
                <CopyField label="Ahorros Bancolombia" value="24300000912" />
                <CopyField label="Razón Social" value="Niña Gato SAS" />
                <CopyField label="NIT" value="901374524-0" />
              </Card.Body>
            </Card>
          </Col>
        </Row>}

        <MultipleFileFormGroup {...props} />
      </Card.Body>
    </Card>
  )
}
